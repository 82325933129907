import $ from "jquery";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

const addCalendarContainer = (el) => {
  let calendarContainer = $(el).parents(".field").find(".calendar-container");

  if (calendarContainer.length === 0) {
    $(el).parents(".field").append("<div class='calendar-container'></div>");
    calendarContainer = $(el).parents(".field").find(".calendar-container");
  }

  return calendarContainer;
};

export const initStartEndDateFields = (startInput, endInput, isFormatted) => {
  if (
    $(`${startInput}:not(.flatpickr-input)`).length < 1 ||
    $(`${endInput}:not(.flatpickr-input)`).length < 1
  ) {
    return false;
  }

  let startConfig = {};
  let endConfig = {
    onChange(selectedDates, dateStr) {
      endConfig = Object.assign(endConfig, { defaultDate: dateStr });
    },
  };

  const mainConfig = {
    altInput: true,
    altFormat: isFormatted ? "l, F j, Y" : "F j, Y",
    dateFormat: isFormatted ? "m/d/Y" : "Y-m-d",
    minDate: new Date(),
    disableMobile: true,
  };

  try {
    const endCalendarContainer = addCalendarContainer(endInput);
    let config = {};
    config = Object.assign(config, mainConfig, {
      static: true,
      appendTo: endCalendarContainer.get(0),
    });
    const endPicker = flatpickr(endInput, config);

    const startCalendarContainer = addCalendarContainer(startInput);
    startConfig = Object.assign(startConfig, mainConfig, {
      onChange(_selectedDates, dateStr) {
        const endInputPicker = Array.isArray(endPicker)
          ? endPicker[0]
          : endPicker;
        endInputPicker.set("minDate", dateStr);

        const d1 = new Date(dateStr);
        const d2 = endInputPicker.selectedDates[0];

        if (d1 > d2) {
          d2.setDate(d1.getDate() + 1);
          endInputPicker.setDate(d2, true);
        }

        endInputPicker.toggle();
      },
      static: true,
      appendTo: startCalendarContainer.get(0),
    });

    flatpickr(startInput, startConfig);
  } catch (err) {
    window.Sentry.captureException(err);
  }

  return true;
};

const getTimezoneAgnosticDate = (dateStr) => {
  try {
    const date = dateStr.split("-");
    return new Date(
      parseInt(date[0], 10),
      parseInt(date[1], 10) - 1,
      parseInt(date[2], 10),
    );
  } catch (err) {
    window.Sentry.captureException(err);
    return new Date();
  }
};

const addDaysToDate = (startDate, days) => {
  const date = getTimezoneAgnosticDate(startDate);
  date.setDate(date.getDate() + days);
  return date;
};

export const initClearDatePicker = () => {
  document.querySelectorAll("[data-clear-datepicker]").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      // eslint-disable-next-line no-underscore-dangle
      document.getElementById(el.dataset.clearDatepicker)._flatpickr.clear();
      const clearButton = el.parentElement.querySelector(".clear-input");
      const scheduleImage = el.parentElement.querySelector(".schedule-img");
      scheduleImage?.classList.remove("tw-hidden");
      clearButton?.classList.add("tw-hidden");
      return false;
    });
  });
};

export const initDatePickerInBlock = (block, itemClass, itemConfig) => {
  const itemClassValue = itemClass || ".flatpickr";
  const itemConfigValue = itemConfig || {};
  const dateInputs = $(block).find(`${itemClassValue}:not(.flatpickr-input)`);
  const mainConfig = {
    altInput: true,
    altFormat: "l, F j, Y",
    dateFormat: "Y-m-d",
    minuteIncrement: 1,
  };
  const timeConfig = {
    altFormat: "D, F j, Y h:i K",
    dateFormat: "Y-m-d H:i:S",
    enableTime: true,
  };

  dateInputs.each((_ind, el) => {
    const dateInputId = $(el).attr("id");
    const calendarContainer = addCalendarContainer(el);

    // Use data attributes to override default config
    if (el.dataset.altformat || el.dataset.dateformat) {
      itemConfigValue.altFormat = el.dataset.altformat;
      itemConfigValue.dateFormat = el.dataset.dateformat;
    }

    let config = {};
    config = Object.assign(config, mainConfig, itemConfigValue, {
      static: true,
      appendTo: calendarContainer.get(0),
    });
    if (el.dataset.enabletime) {
      config = Object.assign(config, timeConfig);
    }

    flatpickr(`#${dateInputId}`, config);
  });
  initClearDatePicker();
};
window.initDatePickerInBlock = initDatePickerInBlock;

export const initTimepicker = (el) => {
  if (el.classList.contains("flatpickr-input")) {
    return;
  }

  const calendarContainer = addCalendarContainer(el);
  flatpickr(el, {
    enableTime: true,
    noCalendar: true,
    dateFormat: "h:i K",
    static: true,
    minuteIncrement: el.dataset.minuteIncrement || 1,
    appendTo: calendarContainer.get(0),
    onValueUpdate: (selected, timeStr, instance) => {
      if (instance.element.dataset.clear) {
        const clearButton =
          instance.element.parentElement.querySelector(".clear-input");
        const scheduleImage =
          instance.element.parentElement.querySelector(".schedule-img");
        if (timeStr !== "") {
          clearButton?.classList.remove("tw-hidden");
          scheduleImage?.classList.add("tw-hidden");
        } else {
          scheduleImage?.classList.remove("tw-hidden");
          clearButton?.classList.add("tw-hidden");
        }
      }
    },
  });
};

export const initTimePickerInBlock = (block) => {
  const container = block || document;
  container.querySelectorAll(".timepicker").forEach((el) => {
    initTimepicker(el);
  });
  initClearDatePicker();
};

window.initTimePickerInBlock = initTimePickerInBlock;

export const initDatePicker = () => {
  initDatePickerInBlock("body");
  initTimePickerInBlock(document.querySelector("body"));
};
window.initDatePicker = initDatePicker; // Used in air_request new.js.erb and show.js.erb and in registration index.js.erb

export const initFlatpickrMulti = (startInput, endInput, itemConfig) => {
  if (startInput === null || endInput === null) {
    return;
  }

  const itemConfigValue = itemConfig || {};

  try {
    let minDate =
      endInput.dataset.mindate == null ? new Date() : endInput.dataset.mindate;
    const maxDayCount = endInput.dataset.maximumdays
      ? parseInt(endInput.dataset.maximumdays)
      : null;

    const endConfig = {
      altInput: true,
      altFormat: endInput.dataset.altformat || "l, F j, Y",
      minDate: minDate === "false" ? null : minDate,
      maxDate:
        endInput.dataset.maxdate === "false" ? null : endInput.dataset.maxdate,
      dateFormat: endInput.dataset.dateformat || "Y-m-d",
    };
    const endPicker = flatpickr(
      endInput,
      Object.assign(endConfig, itemConfigValue),
    );
    minDate =
      startInput.dataset.mindate == null
        ? new Date()
        : startInput.dataset.mindate;
    const endInputPicker = Array.isArray(endPicker) ? endPicker[0] : endPicker;
    const startConfig = {
      altInput: true,
      altFormat: startInput.dataset.altformat || "l, F j, Y",
      minDate: minDate === "false" ? null : minDate,
      maxDate:
        startInput.dataset.maxdate === "false"
          ? null
          : startInput.dataset.maxdate,
      dateFormat: startInput.dataset.dateformat || "Y-m-d",
      onChange(selectedDates, dateStr) {
        const endDate = getTimezoneAgnosticDate(dateStr);
        endInputPicker.set("minDate", endDate);
        if (maxDayCount) {
          const maxDate = addDaysToDate(dateStr, maxDayCount);
          const maxEndDate = getTimezoneAgnosticDate(
            endInputPicker.config.maxdate,
          );
          endInputPicker.set(
            "maxDate",
            maxDate < maxEndDate ? maxDate : maxEndDate,
          );
        }

        const d1 = endDate;
        const d2 = endInputPicker.selectedDates[0] || endDate;

        if (d1 >= d2) {
          const nextDay = new Date(d1);
          nextDay.setUTCDate(d1.getUTCDate() + 1);
          endInputPicker.setDate(nextDay, true);
        }
      },
    };
    if (maxDayCount) {
      const maxDate = addDaysToDate(startInput.value, maxDayCount);
      const maxEndDate = getTimezoneAgnosticDate(endInputPicker.config.maxdate);
      endInputPicker.set(
        "maxDate",
        maxDate < maxEndDate ? maxDate : maxEndDate,
      );
    }
    flatpickr(startInput, Object.assign(startConfig, itemConfigValue));
  } catch (err) {
    window.Sentry.captureException(err);
  }
};

export const initAllFlatpickrMulti = (itemConfig) => {
  document.querySelectorAll(".flatpickr-multi").forEach((el) => {
    const startInput = el.querySelector(".flatpickr-multi-start");
    const endInput = el.querySelector(".flatpickr-multi-end");

    initFlatpickrMulti(startInput, endInput, itemConfig);
  });
};

export const bindStartEndDateFields = () => {
  document
    .querySelectorAll("[data-start-end-date-formatted]")
    .forEach((startElement) => {
      const formatted = startElement.dataset.startEndDateFormatted === "true";
      initStartEndDateFields(
        `#${startElement.id}`,
        startElement.dataset.endDateSelector,
        formatted,
      );
    });
  initDatePicker();
};
document.addEventListener("DOMContentLoaded", bindStartEndDateFields);
document.addEventListener("startenddatefields:rebind", bindStartEndDateFields);
