import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const { context } = this.element.dataset;
    this.onFrameLoad(context);
  }

  onFrameLoad(context) {
    if (context === "build") {
      document.dispatchEvent(
        new CustomEvent("crossEventsTable:updated", { bubbles: true }),
      );
    }

    if (context === "update_events") {
      document.dispatchEvent(
        new CustomEvent("crossEventsTable:updateEvents", { bubbles: true }),
      );
    }

    if (context === "new") {
      document.dispatchEvent(
        new CustomEvent("crossEventsModal:opened", { bubbles: true }),
      );
    }
  }
}
