import { showModal } from "../Modal";
import { initDatePicker } from "../DatetimeInit";
import { initReadOnly } from "../components/SignaturePad";
import { initializeSemanticUI } from "../../plugins/SemanticUI";

const init = () => {
  const modalView = document.getElementById("modal_view");
  if (modalView) {
    modalView.classList.add("full_height");
    showModal("#modal_view");
    initializeSemanticUI();
    initDatePicker();
    initReadOnly();
  }
};

document.addEventListener("registration:init", init);
