import Dropdown from "./dropdown_controller";

export default class extends Dropdown {
  selectOption(option) {
    super.selectOption(option);
    this.toggle_dynamic_component(option.dataset.value);
  }

  toggle_dynamic_component(value) {
    const { dynamicComponentSelector } = this.element.dataset;

    if (dynamicComponentSelector) {
      const dynamicComponent = document.querySelector(dynamicComponentSelector);
      if (dynamicComponent) {
        if (value === this.element.dataset.dynamicTriggerValue) {
          dynamicComponent.classList.remove("tw-hidden");
        } else {
          dynamicComponent.classList.add("tw-hidden");
        }
      }
    }
  }
}
