import FlowbitePopoverController from "./flowbite/flowbite_popover_controller";

export default class extends FlowbitePopoverController {
  popoverOptions = {
    ...super.popoverOptions,
    offset: 5,
  };

  connect() {
    super.connect();
  }
}
