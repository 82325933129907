import { Controller } from "@hotwired/stimulus";
import { initSortable, updateSortOrder } from '../../src/plugins/SortableJS';

// This class should probably be a full reimplementation of SortableJS
// but it's used all over the place so for now just wrapping it.
export default class extends Controller {
  static values = {
    id: String
  }

  connect = () => {
    const hasSortableItems = document.querySelectorAll('.js-sort-item').length > 0;
    if (hasSortableItems) {
      initSortable(
        this.idValue,
        '.js-sort-item',
        '.js-sort-item__position',
        '.js-sort-item',
        updateSortOrder,
      );
    }
  }
}
