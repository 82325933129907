import { Controller } from "@hotwired/stimulus";
import toastr from "../../src/plugins/Toastr";
import { initExporter } from "../../src/custom/exporter";
import { i18n } from "../../src/custom/Internationalization";
import { csvToNumberArray } from "../../src/custom/Utils";

export default class extends Controller {
  static targets = [
    "objectIds",
    "allEvents", // allEvents is field
    "startRecord",
    "endRecord",
    "totalRecords",
    "recordBlock",
    "paginationMessage",
    "singlePageStartMessage",
    "singlePageEndMessage",
  ];

  removedIds = [];

  syncObjectIds = () => {
    const buildObjectIdsField = document.getElementById("build_object_ids");

    if (this.objectIdsTarget && buildObjectIdsField) {
      buildObjectIdsField.value = this.objectIdsTarget.value;
    }
  };

  syncCount = () => {
    const checkedValues = csvToNumberArray(this.objectIdsTarget.value || "");

    this.totalRecordsTarget.innerHTML = checkedValues.length;
    this.endRecordTarget.innerHTML = checkedValues.length;
  };

  cleanRemovedEvents = () => {
    const rows = document.querySelectorAll(
      "#cross_events_list [id^='events_row_']",
    );

    rows.forEach((row) => {
      const rowId = Number(row.querySelector("input")?.value);

      if (rowId) {
        if (this.removedIds.indexOf(rowId) !== -1) {
          row?.parentNode?.removeChild(row);
        }
      }
    });
  };

  switchPaginationMessage = () => {
    if (!this.hasRecordBlockTarget) return;

    const targets = [
      this.startRecordTarget,
      this.endRecordTarget,
      this.singlePageEndMessageTarget,
      this.singlePageStartMessageTarget,
    ];

    if (this.totalRecordsTarget.dataset.amount > 25) {
      // Hide the detailed record block that can't be synced with pagination
      targets.forEach((target) => target.classList.add("tw-hidden"));
      // Display the simplified record block when pagination is used
      this.paginationMessageTarget.classList.remove("tw-hidden");
    }
  };

  removeEvent = (e) => {
    e.preventDefault();

    let checkedValues = csvToNumberArray(this.objectIdsTarget.value || "");
    const id = Number(e.currentTarget.dataset.eventid);
    const index = checkedValues.indexOf(id);
    const row = document.getElementById(`events_row_${id}`);

    if (index === -1) {
      this.cleanRemovedEvents();
    } else {
      // If the id is present in the array remove it
      checkedValues = checkedValues.filter((el) => el !== "" && el !== id);
      this.objectIdsTarget.value = checkedValues.join(",");
      this.allEventsTarget.value = "false";
      row.classList.add("disabled");
      row?.parentNode?.removeChild(row);
      // Push this id into the removedIds array for tracking
      this.removedIds.push(id);
      this.syncCount();
      this.syncObjectIds();
    }
  };

  afterDownload = (uuid, data) => {
    // Replace the element with the new anchor link
    const generatingElement = document.querySelector(
      `#report_download_${uuid} .generating`,
    );
    generatingElement.outerHTML = `<a href="${data.url}" target="_blank" class="tw-mx-4">${i18n.t("actions.download")}</a>`;

    toastr.clear();
  };

  initButtons = () => {
    const reportType = document.getElementById("cross_event_report_type");
    const selectEvents = document.getElementById("new_report_modal");
    const downloadBtn = document.getElementById("download_button");

    reportType.addEventListener("change", (e) => {
      if (e.currentTarget.value !== "placeholder") {
        selectEvents.classList.remove("disabled");
      } else {
        selectEvents.classList.add("disabled");
      }
    });

    downloadBtn.addEventListener("click", (e) => {
      e.currentTarget.classList.add("disabled");
    });
  };

  enableDownloadButton = () => {
    const checkedValues = csvToNumberArray(this.objectIdsTarget.value || "");
    const downloadButton = document.getElementById("download_button");

    if (checkedValues.length === 0) {
      downloadButton.classList.add("disabled");
      return;
    }
    downloadButton.classList.remove("disabled");
  };

  handleCrossEventsTableUpdated() {
    this.syncCount();
    this.cleanRemovedEvents();
    this.switchPaginationMessage();
    this.enableDownloadButton();
  }

  handleCrossEventsModalUpdates() {
    this.removedIds = [];
  }

  connect() {
    this.syncObjectIds();
    initExporter(this.afterDownload, ".cross_event_report_export");
    this.initButtons();
  }
}
