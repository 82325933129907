import $ from 'jquery';

export const initIBSearchScripts = (maxRooms) => {
  const updateRoomNights = () => {
    let number = 0;
    $('.select_rooms select').each((ind, el) => {
      const thisValue = +$(el).val();
      number += thisValue;

      if (number > parseInt(maxRooms, 10)) {
        $('.room_warning').show();
        $('.reserve_button').hide();
      } else {
        $('.room_warning').hide();
        $('.reserve_button').show();
      }

      const roomNights = parseInt($('.total_nights').text(), 10) * number;

      $('.room_count')
        .text(number);

      $('.room_nights')
        .text(roomNights);
    });
  };

  const selectedRoomsAmount = () => $.makeArray($('.select_rooms select')).map(
    (elem) => parseInt($(elem).val(), 10),
  ).reduce(
    (prev, elem) => prev + elem,
  );

  const updateReserveButton = () => {
    $('input.reserve_button').prop('disabled', !(selectedRoomsAmount() > 0));
  };

  $('#filter-menu .item').on('click', (e) => {
    $('.rooms_list tr').show();
    $('.rooms_list tr.no_results').hide();
    $(`.rooms_list tr.${$(e.target).data('hide')}`).hide();
    if ($('.rooms_list tr:visible').length === 0) {
      $('.rooms_list tr.no_results').show();
    }
  });

  $('.select_rooms select').on('change', () => {
    updateRoomNights();
  });

  $('.regbook_room_details__info').on('click', (e) => {
    $(e.target).addClass('loading');
  });

  $('.select_rooms select').on('change', () => {
    updateReserveButton();
  });

  updateReserveButton();
  updateRoomNights();
};
