import { Controller } from "@hotwired/stimulus";
import Splide from '@splidejs/splide';

Splide.defaults = {
  start: 0,
  perPage: 1,
};

export default class extends Controller {
  static values = {
    options: { type: Object, default: {} }
  }

  connect() {
    new Splide(this.element, this.optionsValue).mount();
  }
}
