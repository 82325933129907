import "@hotwired/turbo-rails";
import * as Turbo from "@hotwired/turbo";
Turbo.session.drive = false;

Turbo.StreamActions.scroll_to = function () {
  const target = this.targetElements[0];
  target.scrollIntoView();
};

Turbo.StreamActions.empty = function () {
  const target = this.targetElements[0];
  target.innerHTML = "";
};