import { Controller } from "@hotwired/stimulus";
import toastr from "toastr";
import { i18n } from "../../src/custom/Internationalization";

export default class extends Controller {
  static targets = [
    "mandatory",
    "display",
    "enable"
  ];

  static values = {
    toggleWarning: String
  }

  toggleMandatory = () => {
    if (this.mandatoryTarget.checked) {
      this.displayTarget.checked = true;
      this.enableTarget.checked = true;
    } else {
      this.showWarning();
    }
  }

  toggleDisplay = () => {
    if (this.displayTarget.checked) {
      this.enableTarget.checked = true;
    } else {
      this.mandatoryTarget.checked = false;
      this.showWarning();
    }
  }

  toggleEnable = () => {
    if (!this.enableTarget.checked) {
      this.mandatoryTarget.checked = false;
      this.displayTarget.checked = false;
      this.showWarning();
    }
  }

  showWarning = () => {
    if (this.hasToggleWarningValue) {
      toastr.warning(
        i18n.t(this.toggleWarningValue),
        '',
        {
          timeOut: 0,
          iconClass: 'toast-warning',
        },
      );
    }
  }
}
