/* eslint-disable no-use-before-define */
import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import {
  clearDatePicker,
  csvToNumberArray,
  resetFormData,
  formatToUTCDate,
} from "../../src/custom/Utils";

export default class extends Controller {
  static targets = [
    "rowCheckbox",
    "buildObjectIds",
    "allEvents", // allEvents is checkbox
    "reportName",
    "clearbtn",
    "form",
    "startDate",
    "endDate",
    "selectedCount",
    "targetFrame",
  ];

  objectIdsField = document.getElementById("cross_event_object_ids");

  allEventsField = document.getElementById("cross_event_all_events");

  reportNameField = document.getElementById("cross_event_name");

  checkedValues = csvToNumberArray(this.objectIdsField.value || "");

  syncObjectIds = () => {
    if (this.objectIdsField && this.buildObjectIdsTarget) {
      this.checkedValues = [...new Set(this.checkedValues)].filter(
        (value) =>
          typeof value === "number" &&
          !Number.isNaN(value) &&
          Number(value) !== 0,
      );
      this.buildObjectIdsTarget.value = this.objectIdsField.value;
      // Update the selected count
      this.selectedCountTarget.innerHTML = this.checkedValues.length;
    }
  };

  handleRowClick = (e) => {
    if (e.target.tagName === "INPUT" && e.target.type === "checkbox") {
      return;
    }

    const row = e.currentTarget;
    const checkbox = row.querySelector("input[type='checkbox']");

    if (checkbox) {
      checkbox.checked = !checkbox.checked;
      // Dispatch a change event to trigger handleCheckboxChange
      checkbox.dispatchEvent(new Event("change", { bubbles: true }));
    }
  };

  handleCheckboxChange = (e) => {
    if (e.target.checked) {
      this.addEvent(Number(e.target.value));
    } else {
      this.removeEvent(Number(e.target.value));
    }
  };

  handleReportNameChange = (e) => {
    if (e.currentTarget.value !== "") {
      this.reportNameField.value = e.currentTarget.value;
    }
  };

  handleSearchAndFilter = () => {
    this.allEventsField.value = "false";
    this.checkedValues = [];
    this.syncObjectIds();
    this.syncCheckboxes();
  };

  updateFilterCount = (id, extCount) => {
    const button = document.getElementById(`${id}__btn`);
    const countDisplay = document.getElementById(`${id}__count`);
    const icon = button.querySelector('[class*="g-icon"]');
    const formData = new FormData(this.formTarget);
    const name = `${id}[]`;
    const formCount = [...formData.entries()].filter(
      (el) => el[0] === name,
    ).length;
    const count = extCount || formCount;

    if (count === 0) {
      countDisplay.classList.add("tw-hidden");
      icon.classList.remove("tw-hidden");
      return;
    }
    countDisplay.innerHTML = count;
    countDisplay.classList.remove("tw-hidden");
    icon.classList.add("tw-hidden");
  };

  handleSearchAndFilterChange = (e) => {
    this.clearbtnTarget.classList.remove("tw-invisible");
    this.clearbtnTarget.classList.add("tw-visible");

    if (e && e.currentTarget.type === "checkbox") {
      const id = e.currentTarget.name.replace("[]", "");
      this.updateFilterCount(id);
    }
  };

  handleFilterClear = () => {
    const buttons = document.querySelectorAll('button[id$="__btn"]');

    buttons.forEach((btn) => {
      const id = btn.id.replace("__btn", "");
      this.updateFilterCount(id, 0);
    });
  };

  clearSearchAndFilter = () => {
    this.clearbtnTarget.classList.remove("tw-visible");
    this.clearbtnTarget.classList.add("tw-invisible");

    if (this.startDatePicker) {
      clearDatePicker(this.startDatePicker);
    }
    if (this.endDatePicker) {
      clearDatePicker(this.endDatePicker);
    }
    // Clear the hidden date fields
    document.getElementById("start_date").value = "";
    document.getElementById("end_date").value = "";

    // Update data to clear filters after submit
    this.formTarget.dataset.clearfilters = true;
  };

  handleSelectAllChange = (e) => {
    const allEventsIds = JSON.parse(this.allEventsTarget.dataset.alleventsids);
    if (e.target.checked) {
      allEventsIds.forEach((id) => {
        this.addEvent(id);
      });
      this.allEventsField.value = "true";
    } else {
      allEventsIds.forEach((id) => {
        this.removeEvent(id);
      });
      this.allEventsField.value = "false";
    }

    this.syncCheckboxes(allEventsIds);
  };

  addEvent = (id) => {
    // Ensure the passed `id` is a number
    if (typeof id !== "number" || Number.isNaN(id)) {
      // TODO: Update this to sentry error if possible
      // console.error(`Invalid id: ${id}. Only numbers are allowed.`);
      return;
    }

    if (!this.checkedValues.includes(id)) {
      this.checkedValues.push(id);
    }

    this.objectIdsField.value = this.checkedValues
      .filter((el) => el !== "")
      .join(",");
    this.syncObjectIds();
  };

  removeEvent = (id) => {
    // Ensure the passed `id` is a number
    if (typeof id !== "number" || Number.isNaN(id)) {
      // TODO: Update this to sentry error if possible
      // console.error(`Invalid id: ${id}. Only numbers are allowed.`);
      return;
    }

    const index = this.checkedValues.indexOf(id);
    if (index >= 0) {
      this.checkedValues = this.checkedValues.filter(
        (el) => el !== "" && el !== id,
      );
      this.objectIdsField.value = this.checkedValues.join(",");
    }

    this.allEventsTarget.checked = false;

    const row = document.querySelector(`#cross_event_list .events_row_${id}`);
    row?.parentNode?.removeChild(row);

    this.syncObjectIds();
  };

  syncCheckboxes = () => {
    const checkboxes = this.rowCheckboxTargets.filter((checkbox) => {
      return this.checkedValues.includes(Number(checkbox.value));
    });

    if (checkboxes.length === 0) {
      this.rowCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = false;
      });
      return;
    }
    checkboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });

    this.allEventsTarget.checked = this.allEventsField.value === "true";
  };

  initDatePicker = () => {
    const startDate = document.getElementById("start_date");
    const endDate = document.getElementById("end_date");
    const modalContainer = this.element.closest(".tf-modal");

    if (this.startDatePicker) {
      this.startDatePicker.destroy();
    }
    if (this.endDatePicker) {
      this.endDatePicker.destroy();
    }

    this.startDatePicker = flatpickr(this.startDateTarget, {
      dateFormat: "D, M j, Y", // Display format
      defaultDate: Date.parse(this.startDateTarget.defaultValue),
      appendTo: modalContainer,
      onOpen: () => {
        if (this.endDatePicker.selectedDates[0]) {
          this.startDatePicker.set(
            "maxDate",
            this.endDatePicker.selectedDates[0],
          );
        }
      },
      onChange: (selectedDates) => {
        if (selectedDates[0]) {
          this.endDatePicker.set("defaultDate", selectedDates[0]);

          // Open the endDatePicker if its date is not selected
          if (!this.endDatePicker.selectedDates[0]) {
            this.endDatePicker.open();
          }

          startDate.value = formatToUTCDate(selectedDates[0]);

          this.handleSearchAndFilterChange();
        }
      },
      onClose: () => {
        // Handle if the user manually removed the date
        if (!this.startDatePicker.selectedDates[0]) {
          startDate.value = "";
        }
      },
    });

    this.endDatePicker = flatpickr(this.endDateTarget, {
      dateFormat: "D, M j, Y", // Display format
      defaultDate: Date.parse(this.endDateTarget.defaultValue),
      appendTo: modalContainer,
      onOpen: () => {
        if (this.startDatePicker.selectedDates[0]) {
          this.endDatePicker.set(
            "minDate",
            this.startDatePicker.selectedDates[0],
          );
        }
      },
      onChange: (selectedDates) => {
        if (selectedDates[0]) {
          endDate.value = formatToUTCDate(selectedDates[0]);

          this.handleSearchAndFilterChange();
        }
      },
      onClose: () => {
        // Handle if the user manually removed the date
        if (!this.endDatePicker.selectedDates[0]) {
          endDate.value = "";
        }
      },
    });
  };

  handleReset() {
    resetFormData(this.formTarget);
    // Ensure the form is submitted as a Turbo Stream
    this.formTarget.setAttribute("data-turbo", "true");
    this.formTarget.setAttribute("data-turbo-stream", "true");
    // Submit clear form to update results
    this.formTarget.dispatchEvent(
      new CustomEvent("submit", {
        bubbles: true,
      }),
    );
  }

  handleSubmitEnd(event) {
    if (event.target === this.formTarget) {
      if (this.formTarget.dataset.clearfilters === "true") {
        this.handleFilterClear();
        this.formTarget.dataset.clearfilters = false;
      }
    }
  }

  handleUpdateEvents() {
    this.syncCheckboxes();
  }

  connect() {
    // NOTE: All actions on unfiltered data hit the new path which replaces the entire modal and disconnects/reconnects this controller
    this.syncObjectIds();
    this.syncCheckboxes();
    this.initDatePicker();

    if (this.reportNameField.value !== "") {
      this.reportNameTarget.value = this.reportNameField.value;
    }

    this.handleReset = this.handleReset.bind(this);
    this.handleSubmitEnd = this.handleSubmitEnd.bind(this);
    this.handleUpdateEvents = this.handleUpdateEvents.bind(this);

    if (this.hasFormTarget) {
      this.formTarget.addEventListener("reset", this.handleReset);
    }
  }

  disconnect() {
    if (this.hasFormTarget) {
      this.formTarget.removeEventListener("reset", this.handleReset);
    }
  }
}
