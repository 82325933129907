import $ from "jquery";
import { getCSRFTokenValue } from "../CsrfToken";


export const checkProposalRequestStatus = (elem) => {
  if (elem === null) {
    return;
  }

  const url = elem.dataset.pollingUrl;
  const delay = elem.dataset.pollingDelay;

  setTimeout(() => {
    $.ajax({
      dataType: "script",
      url,
      type: "GET",
      headers: {
        "X-CSRF-Token": getCSRFTokenValue(),
      },
    });
  }, delay);
};
