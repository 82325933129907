import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["selectAll", "checkbox"];

  connect = () => {
    if (!this.hasSelectAllTarget || this.selectAllTarget.checked) {
      this.checkboxTargets.forEach((checkbox) => {
        checkbox.checked = true;
      });
    }

    const checked = this.checkboxTargets.filter((checkbox) => checkbox.checked);
    if (checked.length === 1) {
      checked[0].parentNode.classList.add("disabled");
    }
  }

  toggleSelectAll = () => {
    this.checkboxTargets.forEach((checkbox) => {
      if (
        checkbox.dataset.defaultAttendeeType === "true" &&
        this.selectAllTarget.checked === false
      ) {
        checkbox.parentNode.classList.add("disabled");
      } else {
        checkbox.checked = this.selectAllTarget.checked;
        checkbox.parentNode.classList.remove("disabled");
      }
    });
  };

  toggleCheckbox = (event) => {
    let allChecked = true;
    const checkedCount = this.checkboxTargets.filter((checkbox) => checkbox.checked).length;
    if (checkedCount === 0) {
      event.currentTarget.checked = true;
      return;
    }

    this.checkboxTargets.forEach((elem) => {
      if (!elem.checked) {
        allChecked = false;
      }
      elem.parentNode.classList.remove("disabled");
    });

    if (this.hasSelectAllTarget) {
      this.selectAllTarget.checked = allChecked;
    }

    const checked = this.checkboxTargets.filter((checkbox) => checkbox.checked);
    if (checked.length === 1) {
      checked[0].parentNode.classList.add("disabled");
    }
  };
}
