import { Controller } from "@hotwired/stimulus";
import { initDatePickerInBlock } from "../src/custom/DatetimeInit";

export default class extends Controller {
  static values = {
    format: { type: String, default: "Y-m-d" },
  };

  connect() {
    initDatePickerInBlock(`#${this.element.id}`, null, { dateFormat: this.formatValue });
  }
}
