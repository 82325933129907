import { Controller } from "@hotwired/stimulus";
import { initDatePicker } from "../src/custom/DatetimeInit";

export default class extends Controller {
  static targets = [
    "occupantRegistrationSelector",
    "firstName",
    "lastName",
    "email",
    "phone",
    "title",
    "company",
    "address",
    "city",
    "postalCode",
    "state",
    "country"
  ];

  connect() {
    initDatePicker();
  }

  selectRegistration(e) {
    try {
      const content = JSON.parse(this.occupantRegistrationSelectorTarget.value);
      this.firstNameTarget.value = content.first_name;
      this.lastNameTarget.value = content.last_name;
      this.emailTarget.value = content.email;
      this.phoneTarget.value = content.phone;
      this.titleTarget.value = content.title;
      this.companyTarget.value = content.company;
      this.addressTarget.value = content.address;
      this.cityTarget.value = content.city;
      this.postalCodeTarget.value = content.postal_code;
    } catch(e) {
      console.error("Unable to set fields to registration content", e);
    }
  }
}
