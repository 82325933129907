import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {

  static targets = ["countries", "states"]
  async updateStates() {
    if (!this.hasCountriesTarget || !this.hasStatesTarget) {
      return;
    }

    const selectedCountry = this.countriesTarget.options[this.countriesTarget.selectedIndex];
    const countryCode = selectedCountry.value;

    if (countryCode === "") {
      return;
    }

    while (this.statesTarget.options.length > 0) {
      this.statesTarget.remove(0);
    }

    const request = new FetchRequest('get', `/states/${countryCode}`)
    const response = await request.perform()
    if (response.ok) {
      const data = await response.json;
      Object.keys(data).forEach(key => {
        this.addOption(key, data[key]);
      });
      this.setPresetState(data);
    }
  }

  addOption = (value, text) => {
    const option = document.createElement("option");
    option.value = value;
    option.text = text;
    this.statesTarget.add(option);
  }

  // When using a location autocomplete (google maps) the state is pre-known.
  // this will ensure it is set correctly after being selected.
  setPresetState = (stateOptions) => {
    if (this.hasStatesTarget && this.countriesTarget.dataset.presetState) {
      const stateCode = Object.keys(stateOptions).find((key) => stateOptions[key] === this.countriesTarget.dataset.presetState);
      if (stateCode) {
        this.statesTarget.value = stateCode;
      }
    }
  }
}
