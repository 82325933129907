import $ from 'jquery';
import Cookies from 'js-cookie';
import Splide from '@splidejs/splide';
import { getCSRFTokenValue } from '../CsrfToken';
import { removeQuerystring } from '../UrlParams';
import { showModal, hideModal } from '../Modal';

const getSelectedBidIds = (section, label) => {
  const bidIds = [];

  $(`.reg_table .select_bid_${section}_${label} input[type='checkbox']:checked`).each((index, elem) => {
    bidIds.push($(elem).data('bid-id'));
  });

  return bidIds;
};

const initBulkActions = () => {
  document.querySelectorAll('[data-ebid-bulk-actions').forEach((table) => {
    const tableId = table.id;
    document.querySelectorAll(`#${tableId} a`).forEach((link) => {
      // eslint-disable-next-line consistent-return
      link.addEventListener('click', (e) => {
        const originalUrl = e.currentTarget.getAttribute('href');
        if (!originalUrl) return false; // Don't do anything if there is no url

        const result = getSelectedBidIds(table.dataset.section, table.dataset.label);
        const newUrl = removeQuerystring(originalUrl).concat('?bid_ids=', result);
        link.setAttribute('href', newUrl);
      });
    });
  });
};

const updateEAMessages = () => {
  const container = document.getElementById('hotel_details');
  if (container) {
    $.ajax({
      method: 'PATCH',
      url: container.dataset.eaMessagesUrl,
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
    });
  }
};

const setModalDisableCookie = (slideModalId, cookieName) => {
  if ($(`#${slideModalId}-disable-checkbox`)[0] && $(`#${slideModalId}-disable-checkbox`)[0].checked) {
    Cookies.set(cookieName, true);
  }
};

const initIntroModal = (slideModalId = 'intro-slide', cookieName = 'intro_modal_disabled') => {
  const slideContainer = document.getElementById(slideModalId);
  if (!slideContainer) {
    return;
  }

  const numSlides = parseInt(slideContainer.dataset.numberOfSlides, 10) || 1;
  const splide = new Splide('.splide', { cover: false, type: 'slide', arrows: false }).mount();

  splide.on('moved', (newIndex) => {
    if ((newIndex + 1) >= numSlides) {
      document.querySelector('.next_slide').classList.add('is-final');
    }
  });

  const disabledModal = Cookies.get(cookieName);

  if ((disabledModal === undefined || disabledModal !== 'true')) {
    showModal(`#${slideModalId}`);
  } else {
    return;
  }

  document.querySelector('.next_slide').addEventListener('click', (e) => {
    e.preventDefault();
    if (e.currentTarget.classList.contains('is-final')) {
      hideModal(`#${slideModalId}`);
      setModalDisableCookie(slideModalId, cookieName);
      if (e.currentTarget.dataset.submitOnEnd) {
        document.querySelector(e.currentTarget.dataset.submitOnEnd)?.submit();
      }
    } else {
      splide.go('+1');
    }
  });

  document.querySelector('.skip_slide')?.addEventListener('click', (e) => {
    e.preventDefault();
    hideModal(`#${slideModalId}`);
    Cookies.set(cookieName, true);
  });
};

const initEbidsTableScripts = (() => {
  if (window.location.search.search('message_notification=true') === 1) {
    $('.ebids_details__title .location_actions .checkbox').trigger('click');
  }

  $('.switch-communication-panel').on('click', (e) => {
    e.preventDefault();
    $('.ebids_details__title .location_actions .checkbox').trigger('click');
  });
});

const init = () => {
  if (!document.querySelector('[data-ebid-management]')) {
    return;
  }

  initEbidsTableScripts();
  updateEAMessages();
  showModal('#ea-intro');
  initIntroModal('intro-slide', 'intro_modal_disabled');
  initBulkActions();
};
document.addEventListener('DOMContentLoaded', init);
document.addEventListener('beforeunload', updateEAMessages);
