export const titleize = function (string) {
  const words = string.replace(/_/g, " ").split(" ");
  const array = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; ++i) {
    array.push(
      words[i].charAt(0).toUpperCase() + words[i].toLowerCase().slice(1),
    );
  }
  return array.join(" ");
};

export const isHidden = (elem) =>
  elem.classList.contains("hide") ||
  elem.classList.contains("hidden") ||
  elem.classList.contains("tw-hidden") ||
  elem.classList.contains("invisible") ||
  elem.classList.contains("tw-invisible") ||
  elem.style.display === "none";

export const toggleContent = (elem, show, displayClass = "tw-block") => {
  if (show && isHidden(elem)) {
    elem.classList.remove("tw-hidden");
    elem.classList.add(displayClass);
    if (elem.classList.contains("hidden")) {
      elem.classList.remove("hidden");
    }
    if (elem.classList.contains("hide")) {
      elem.classList.remove("hide");
    }
    if (elem.style.display === "none") {
      elem.style.display = "unset";
    }
  }
  if (!show && !isHidden(elem)) {
    elem.classList.add("tw-hidden");
    elem.classList.remove(displayClass);
  }
};

export const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const clearDatePicker = (picker) => {
  if (picker) {
    picker.clear(); // Clear the selected date
    picker.set("maxDate", null);
    picker.set("defaultDate", null);
    picker.set("minDate", null);
  }
};

export const csvToNumberArray = (csv) => {
  if (!csv) return [];
  return csv
    .split(",")
    .map((value) => Number(value))
    .filter((value) => !Number.isNaN(value) && Number(value) !== 0);
};

export const resetFormData = (form) => {
  const formData = Array.from(new FormData(form).entries());

  formData.forEach(([name]) => {
    const fields = form.querySelectorAll(
      `[name="${name}"], [name="${name}[]"]`,
    );
    fields.forEach((field) => {
      if (field.type === "checkbox" || field.type === "radio") {
        field.checked = false;
      } else if (field.tagName === "SELECT") {
        field.selectedIndex = -1;
      } else {
        field.value = "";
      }
    });
  });
};

export function formatToUTCDate(date) {
  if (!(date instanceof Date)) {
    throw new Error("Invalid date object provided.");
  }

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day} 00:00:00 UTC`;
}
