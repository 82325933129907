import $ from 'jquery';
import { getCSRFTokenValue } from '../CsrfToken';

const initGetOrganizations = (url) => {
  $('#new_account')
    .on('change', () => {
      const accountId = $('#new_account option:selected').val();

      $.ajax({
        url,
        type: 'GET',
        data: {
          account_id: accountId,
        },
        headers: {
          'X-CSRF-Token': getCSRFTokenValue(),
        },
      });
    });
};

window.initialize_get_organizations = initGetOrganizations;
