import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  setHiddenFieldAndSubmit = (ev) => {
    const select = document.querySelector("#question_add_sub_question");
    select.value = true;
    const answerUuidField = document.querySelector("#question_answer_uuid");

    answerUuidField.value = ev.target.getAttribute("data-build-uuid");

    document.getElementById("question_form").submit();
  };
}
