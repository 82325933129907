import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import { setConditionalButtonState } from '../components/ConditionalButton';
import { html } from '../confirmationModal';

const initQuestionEvents = () => {
  const dropdownLabel = document.querySelector('#select_question ~ .text');
  const activeSelection = document.querySelector('.item.active.selected');
  let isIconSet = false;

  if (activeSelection && !isIconSet) {
    isIconSet = true;
    dropdownLabel.dataset.value = activeSelection.dataset.value;
  }
  document.addEventListener('change', (e) => {
    if (e.target.selectedOptions && e.target.selectedOptions.length) {
      const selectedValue = e.target.selectedOptions[0].value;
      dropdownLabel.dataset.value = selectedValue;
    }
  });

  $('#question_form')
    .on('cocoon:after-insert', (e, item) => {
      const button = item.find('button.add_subquestion');
      const uuid = uuidv4();

      item.find('input.hidden-build-uuid').val(uuid);
      button.attr('data-build-uuid', uuid);
      setConditionalButtonState(document.querySelector('#select_question'));
    }).on('cocoon:before-remove', (event, item) => {
      // Confirmation of removal for question options/answers
      const row = item.prevObject[0];
      const { answerId } = row.dataset;
      const confirmation = localStorage.getItem(answerId);

      if (!confirmation) {
        event.preventDefault();
        $(html('Confirm Remove Item', 'Are you sure?')).modal({
          onApprove: () => { localStorage.setItem(answerId, true); row.click(); },
        }).modal('show');
      } else {
        localStorage.removeItem(answerId);
      }
    });
};

window.initialize_question_events = initQuestionEvents;
